

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { SvgIcon } from "@mui/material";

// Custom styles for the SidenavCollapse
import {
	collapseItem,
	collapseIconBox,
	collapseIcon,
	collapseText,
	collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, children, active, noCollapse, open, ...rest }) {

	function HomeIcon(props) {
		if (props.icon == 'home') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.07259 13.3333V9.33333H9.73926V13.3333H13.0726V8H15.0726L8.40592 2L1.73926 8H3.73926V13.3333H7.07259Z" fill="white" />				
					</svg>
					</SvgIcon>
			);
		} else if (props.icon == 'not-active') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.8191 2.58671L2.66581 13.74C2.63247 13.6067 2.61914 13.46 2.61914 13.3V3.90671C2.61914 2.49337 3.77247 1.33337 5.19247 1.33337H11.6191C12.5525 1.33337 13.3725 1.83337 13.8191 2.58671Z" fill="white" />
					<path d="M14.9198 1.48667C14.7198 1.28667 14.3932 1.28667 14.1932 1.48667L1.89316 13.7933C1.69316 13.9933 1.69316 14.32 1.89316 14.52C1.99316 14.6133 2.11983 14.6667 2.25316 14.6667C2.3865 14.6667 2.51316 14.6133 2.61316 14.5133L14.9198 2.20667C15.1265 2.00667 15.1265 1.68667 14.9198 1.48667Z" fill="white" />
					<path d="M13.2667 5.14003L8.34001 10.0667C8.03334 10.3734 8.09334 10.8867 8.46668 11.1067L13.3933 14.06C13.84 14.3267 14.4 14.0067 14.4 13.4867V5.6067C14.4067 5.01337 13.6867 4.72003 13.2667 5.14003Z" fill="white" />
					</svg>
				</SvgIcon>
			);
		} else if (props.icon == 'active') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11.6191 1.27332H5.19247C3.77914 1.27332 2.61914 2.43332 2.61914 3.84665V13.24C2.61914 14.44 3.47914 14.9466 4.53247 14.3666L7.78581 12.56C8.13247 12.3666 8.69247 12.3666 9.03247 12.56L12.2858 14.3666C13.3391 14.9533 14.1991 14.4466 14.1991 13.24V3.84665C14.1925 2.43332 13.0391 1.27332 11.6191 1.27332ZM10.8191 6.01998L8.15247 8.68665C8.05247 8.78665 7.92581 8.83332 7.79914 8.83332C7.67247 8.83332 7.54581 8.78665 7.44581 8.68665L6.44581 7.68665C6.25247 7.49331 6.25247 7.17332 6.44581 6.97998C6.63914 6.78665 6.95914 6.78665 7.15247 6.97998L7.79914 7.62665L10.1125 5.31332C10.3058 5.11998 10.6258 5.11998 10.8191 5.31332C11.0125 5.50665 11.0125 5.82665 10.8191 6.01998Z" fill="white" />
					</svg>
				</SvgIcon>
			);
		} else if (props.icon == 'faqs') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="30" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.7393 1.62H5.07259C3.07259 1.62 1.73926 2.95333 1.73926 4.95333V8.95333C1.73926 10.9533 3.07259 12.2867 5.07259 12.2867V13.7067C5.07259 14.24 5.66592 14.56 6.10592 14.26L9.07259 12.2867H11.7393C13.7393 12.2867 15.0726 10.9533 15.0726 8.95333V4.95333C15.0726 2.95333 13.7393 1.62 11.7393 1.62ZM8.40592 9.73333C8.12592 9.73333 7.90592 9.50666 7.90592 9.23333C7.90592 8.95999 8.12592 8.73333 8.40592 8.73333C8.68592 8.73333 8.90592 8.95999 8.90592 9.23333C8.90592 9.50666 8.68592 9.73333 8.40592 9.73333ZM9.24592 6.96666C8.98592 7.13999 8.90592 7.25333 8.90592 7.43999V7.57999C8.90592 7.85333 8.67926 8.07999 8.40592 8.07999C8.13259 8.07999 7.90592 7.85333 7.90592 7.57999V7.43999C7.90592 6.66666 8.47259 6.28666 8.68592 6.13999C8.93259 5.97333 9.01259 5.85999 9.01259 5.68666C9.01259 5.35333 8.73926 5.07999 8.40592 5.07999C8.07259 5.07999 7.79926 5.35333 7.79926 5.68666C7.79926 5.95999 7.57259 6.18666 7.29926 6.18666C7.02592 6.18666 6.79926 5.95999 6.79926 5.68666C6.79926 4.79999 7.51926 4.07999 8.40592 4.07999C9.29259 4.07999 10.0126 4.79999 10.0126 5.68666C10.0126 6.44666 9.45259 6.82666 9.24592 6.96666Z" fill="white" />
					</svg>
				</SvgIcon>
			);
		}
		else if (props.icon == 'updates') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="30" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.1993 1.33337H5.61259C3.18592 1.33337 1.73926 2.78004 1.73926 5.20671V10.7867C1.73926 13.22 3.18592 14.6667 5.61259 14.6667H11.1926C13.6193 14.6667 15.0659 13.22 15.0659 10.7934V5.20671C15.0726 2.78004 13.6259 1.33337 11.1993 1.33337ZM8.40592 12.0067C6.29259 12.0067 4.57259 10.2867 4.57259 8.17337C4.57259 6.06004 6.29259 4.34004 8.40592 4.34004C8.49926 4.34004 8.59926 4.35337 8.69259 4.36004L8.50592 4.14671C8.32592 3.94004 8.34592 3.62004 8.55259 3.44004C8.75926 3.26004 9.07926 3.28004 9.25926 3.48671L10.3726 4.76004C10.3793 4.76671 10.3793 4.77337 10.3859 4.78004C10.3926 4.78671 10.3993 4.78671 10.3993 4.79337C10.4126 4.81337 10.4193 4.84004 10.4326 4.86671C10.4459 4.90004 10.4659 4.92671 10.4726 4.96004C10.4793 4.99337 10.4793 5.02004 10.4859 5.05337C10.4859 5.08671 10.4926 5.11337 10.4926 5.14671C10.4926 5.18004 10.4793 5.20671 10.4659 5.24004C10.4526 5.27337 10.4459 5.30671 10.4326 5.33337C10.4193 5.36004 10.3926 5.38671 10.3726 5.41337C10.3526 5.43337 10.3459 5.46004 10.3259 5.47337C10.3193 5.48004 10.3126 5.48004 10.3059 5.48671C10.2993 5.49337 10.2993 5.50004 10.2926 5.50004L8.99926 6.44671C8.91259 6.51337 8.80592 6.54004 8.70592 6.54004C8.55259 6.54004 8.39926 6.46671 8.29926 6.33337C8.13926 6.11337 8.18592 5.80004 8.40592 5.63337L8.77926 5.36004C8.65259 5.35337 8.53259 5.34004 8.40592 5.34004C6.84592 5.34004 5.57259 6.61337 5.57259 8.17337C5.57259 9.73337 6.84592 11.0067 8.40592 11.0067C9.96592 11.0067 11.2393 9.73337 11.2393 8.17337C11.2393 7.60671 11.0726 7.06671 10.7659 6.60004C10.6126 6.37337 10.6726 6.06004 10.9059 5.90671C11.1326 5.75337 11.4459 5.81337 11.5993 6.04671C12.0193 6.68004 12.2459 7.41337 12.2459 8.17337C12.2393 10.2934 10.5193 12.0067 8.40592 12.0067Z" fill="white"/>
					</svg>
				</SvgIcon>
			);
		}
		else if (props.icon == 'terms') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="30" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.07324 6.00004H12.7399L9.07324 2.33337V6.00004ZM4.40658 1.33337H9.73991L13.7399 5.33337V13.3334C13.7399 13.687 13.5994 14.0261 13.3494 14.2762C13.0993 14.5262 12.7602 14.6667 12.4066 14.6667H4.40658C3.66658 14.6667 3.07324 14.0667 3.07324 13.3334V2.66671C3.07324 1.92671 3.66658 1.33337 4.40658 1.33337ZM7.87324 12.3067L11.0399 9.14004L10.2599 8.20004L7.87324 10.5867L6.81324 9.53337L6.03991 10.3067L7.87324 12.3067Z" fill="white"/>
					</svg>
				</SvgIcon>
			);
		}
		else if (props.icon == 'w9form') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="30" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11.1993 1.33337H5.61259C3.18592 1.33337 1.73926 2.78004 1.73926 5.20671V10.7867C1.73926 13.22 3.18592 14.6667 5.61259 14.6667H11.1926C13.6193 14.6667 15.0659 13.22 15.0659 10.7934V5.20671C15.0726 2.78004 13.6259 1.33337 11.1993 1.33337ZM7.70592 11.6734C7.51259 11.8667 7.14592 12.0534 6.87926 12.0934L5.23926 12.3267C5.17926 12.3334 5.11926 12.34 5.05926 12.34C4.78592 12.34 4.53259 12.2467 4.35259 12.0667C4.13259 11.8467 4.03926 11.5267 4.09259 11.1734L4.32592 9.53337C4.36592 9.26004 4.54592 8.90004 4.74592 8.70671L7.71926 5.73337C7.77259 5.87337 7.82592 6.01337 7.89926 6.17337C7.96592 6.31337 8.03926 6.46004 8.11926 6.59337C8.18592 6.70671 8.25926 6.81337 8.31926 6.89337C8.39259 7.00671 8.47926 7.11337 8.53259 7.17337C8.56592 7.22004 8.59259 7.25337 8.60592 7.26671C8.77259 7.46671 8.96592 7.65337 9.13259 7.79337C9.17926 7.84004 9.20592 7.86671 9.21926 7.87337C9.31926 7.95337 9.41926 8.03337 9.50592 8.09337C9.61259 8.17337 9.71926 8.24671 9.83259 8.30671C9.96592 8.38671 10.1126 8.46004 10.2593 8.53337C10.4126 8.60004 10.5526 8.66004 10.6926 8.70671L7.70592 11.6734ZM11.9859 7.39337L11.3726 8.01337C11.3326 8.05337 11.2793 8.07337 11.2259 8.07337C11.2059 8.07337 11.1793 8.07337 11.1659 8.06671C9.81259 7.68004 8.73259 6.60004 8.34592 5.24671C8.32592 5.17337 8.34592 5.09337 8.39926 5.04671L9.01926 4.42671C10.0326 3.41337 10.9993 3.43337 11.9926 4.42671C12.4993 4.93337 12.7459 5.42004 12.7459 5.92671C12.7393 6.40671 12.4926 6.88671 11.9859 7.39337Z" fill="white"/>
					</svg>
				</SvgIcon>
			);
		}
		else if (props.icon == 'contactus') {
			return (
				<SvgIcon {...props}>
					<svg width="25" height="30" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.7195 11.22L12.9795 13.3267C13.0462 13.88 12.4528 14.2667 11.9795 13.98L9.67285 12.6067C9.51285 12.5134 9.47285 12.3134 9.55951 12.1534C9.89285 11.54 10.0728 10.8467 10.0728 10.1534C10.0728 7.71337 7.97951 5.72671 5.40618 5.72671C4.87951 5.72671 4.36618 5.80671 3.88618 5.96671C3.63951 6.04671 3.39951 5.82004 3.45951 5.56671C4.06618 3.14004 6.39951 1.33337 9.18618 1.33337C12.4395 1.33337 15.0728 3.79337 15.0728 6.82671C15.0728 8.62671 14.1462 10.22 12.7195 11.22Z" fill="white"/>
<path d="M9.07259 10.1534C9.07259 10.9467 8.77926 11.68 8.28592 12.26C7.62592 13.06 6.57926 13.5734 5.40592 13.5734L3.66592 14.6067C3.37259 14.7867 2.99926 14.54 3.03926 14.2L3.20592 12.8867C2.31259 12.2667 1.73926 11.2734 1.73926 10.1534C1.73926 8.98002 2.36592 7.94668 3.32592 7.33335C3.91926 6.94668 4.63259 6.72668 5.40592 6.72668C7.43259 6.72668 9.07259 8.26002 9.07259 10.1534Z" fill="white"/>
					</svg>
				</SvgIcon>
			);
		}
		else {
			return (
				<SvgIcon {...props}>
					<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
				</SvgIcon>
			);
		}

	}

	const [controller] = useMaterialUIController();
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

	return (
		<>
			<ListItem component="li">
				<MDBox
					{...rest}
					sx={(theme) =>
						collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
					}
				>
					{icon.props.children ?
						<HomeIcon icon={icon.props.children} /> :
						<ListItemIcon
							sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
						>
							{typeof icon === "string" ? (
								<Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
							) : (
								icon
							)}
						</ListItemIcon>

					}

					<ListItemText
						primary={name}
						sx={(theme) =>
							collapseText(theme, {
								miniSidenav,
								transparentSidenav,
								whiteSidenav,
								active,
							})
						}
					/>

					<Icon
						sx={(theme) =>
							collapseArrow(theme, {
								noCollapse,
								transparentSidenav,
								whiteSidenav,
								miniSidenav,
								open,
								active,
								darkMode,
							})
						}
					>
						expand_less
					</Icon>
				</MDBox>
			</ListItem>
			{children && (
				<Collapse in={open} unmountOnExit>
					{children}
				</Collapse>
			)}
		</>
	);
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
	active: false,
	noCollapse: false,
	children: false,
	open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
	icon: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	children: PropTypes.node,
	active: PropTypes.bool,
	noCollapse: PropTypes.bool,
	open: PropTypes.bool,
};

export default SidenavCollapse;

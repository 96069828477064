import axios from "axios";

import default_data from "values/default";
import Cookies from 'js-cookie';

const API_URL = default_data.API_URL;

const Loginuser = (username, password) => {
    return axios.post(API_URL + "/api/v0/agents/access", {
        username, password, api_key: default_data.api_key, secret_key: default_data.secret_key
    }).then((response) => {
        Cookies.set('userToken', response.data.response.user.session_id);
        Cookies.set('referral_id', response.data.response.user.session_id);
        return response;
    });
}

const ForgotPassword = (username) => {
    return axios.post(API_URL + "/api/v0/agents/access/forgot",{
        api_key: default_data.api_key, secret_key: default_data.secret_key,email : username
    }).then((response) => {
        return response;
    });
}

const UpdatePassword = (email,token,password) => {
    return axios.post(API_URL + "/api/v0/agents/access/reset",{
        api_key: default_data.api_key,
        secret_key: default_data.secret_key,
        email : email,
        token:token,
        password : password,
        password_confirmation:password
    }).then((response) => {
        return response;
    });
}

const handleLogout = ()=>{
    let host = '.'+document.location.host;
    Cookies.remove('token_state', { path: '/' });
    Cookies.remove('token_state', { path: '/' ,domain:host});
    Cookies.remove('username');
    Cookies.remove('referral_id');
    Cookies.remove('userToken');
    Cookies.remove('uuid');
    Cookies.remove('influencer_data');
    Cookies.remove('start_date');
    Cookies.remove('end_date');
    window.location.reload(true);
}

const login = (email, password) => {
    return axios.post(API_URL + "/login", {
        email, password
    }).then((response) => {
        return response;
    });
}

const register = (signupDetails) => {
    signupDetails.api_key = default_data.api_key;
    signupDetails.secret_key = default_data.secret_key;
    return axios.post(API_URL + "/api/v0/agents/create", signupDetails);
};

const getUserInfo = () => {
    return axios.post(API_URL + "/api/v0/agents/show", {
        api_key: default_data.api_key, secret_key: default_data.secret_key, username: Cookies.get('username')
    }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }).then((response) => {
        return response;
    })
    .catch(error => {
        if (error.response) {
            handleLogout();
        } 
      });
}

const getNotes = (id) => {
    return axios.post(API_URL + "/api/v0/agents/show/opp/"+id+"/notes", {
        "username": Cookies.get('user_name'),
         "user_session": Cookies.get('referral_id'),api_key: default_data.api_key, secret_key: default_data.secret_key
    }, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } }).then((response) => {
        return response.data.response;
    })
}

const updateCompany = (companyDetails) => {
    let data = companyDetails;
    let email = companyDetails.email;
    let account_on_Pause = companyDetails.account_pause;
    data.api_key = default_data.api_key;
    data.secret_key= default_data.secret_key;
    data.username = Cookies.get('username');
    data.user_session = Cookies.get('referral_id');
    delete data['email'];

    for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (!data[key]) {
            const defaultValue = 'deleteValue';
            data[key] = defaultValue;
          }
        }
    }
    data.account_pause = account_on_Pause ? "true" : "false";
    return axios.post(API_URL + "/api/v0/agents/update", data, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } })
    .then((response) => {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
              if (data[key] == 'deleteValue') {
                const defaultValue = '';
                data[key] = defaultValue;
              }
            }
        }
        data.email = email;
        return response;
    })
}


const updateLogin = (loginDetails) => {
    let data = loginDetails;
    data.api_key = default_data.api_key;
    data.secret_key= default_data.secret_key;
    data.username = Cookies.get('username');
    data.user_session = Cookies.get('referral_id');

    return axios.post(API_URL + "/api/v0/agents/update", data, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } })
    .then((response) => {
        return response;
    })
}

const updateOpp = (oppData) => {
    let data = oppData;
    data.api_key = default_data.api_key;
    data.secret_key= default_data.secret_key;
    data.username = Cookies.get('username');
    data.user_session = Cookies.get('referral_id');
    return axios.post(API_URL + "/api/v0/agents/update/opp", data, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } })
    .then((response) => {
        return response;
    })
}

const assignOppUpdate = (assigned_agent, opp_id) => {
    let data = {};
    data.assignedAgent = assigned_agent;
    data.id = opp_id;
    data.api_key = default_data.api_key;
    data.secret_key= default_data.secret_key;
    data.username = Cookies.get('username');
    data.user_session = Cookies.get('referral_id');
    return axios.post(API_URL + "/api/v0/agents/update/opp", data, { headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' } })
    .then((response) => {
        return response;
    })

}

const AuthService = {
    login,
    register,
    getUserInfo,
    Loginuser,
    getNotes,
    handleLogout,
    updateCompany,
    updateOpp,
    assignOppUpdate,
    ForgotPassword,
    UpdatePassword,
    updateLogin
}

export default AuthService;

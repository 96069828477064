const default_data = {
    months : [
        'January','February','March','April','May','June','July','August','September','October','November','December',
    ],
    years : [
        '2020','2021','2022','2023',2024,'2025',
    ],
    states : [
        { abbr: 'AL', name: 'Alabama' },
        { abbr: 'AK', name: 'Alaska' },
        { abbr: 'AZ', name: 'Arizona' },
        { abbr: 'AR', name: 'Arkansas' },
        { abbr: 'CA', name: 'California' },
        { abbr: 'CO', name: 'Colorado' },
        { abbr: 'CT', name: 'Connecticut' },
        { abbr: 'DE', name: 'Delaware' },
        { abbr: 'DC', name: 'District of Columbia' },
        { abbr: 'FL', name: 'Florida' },
        { abbr: 'GA', name: 'Georgia' },
        { abbr: 'GU', name: 'Guam' },
        { abbr: 'HI', name: 'Hawaii' },
        { abbr: 'ID', name: 'Idaho' },
        { abbr: 'IL', name: 'Illinois' },
        { abbr: 'IN', name: 'Indiana' },
        { abbr: 'IA', name: 'Iowa' },
        { abbr: 'KS', name: 'Kansas' },
        { abbr: 'KY', name: 'Kentucky' },
        { abbr: 'LA', name: 'Louisiana' },
        { abbr: 'ME', name: 'Maine' },
        { abbr: 'MD', name: 'Maryland' },
        { abbr: 'MA', name: 'Massachusetts' },
        { abbr: 'MI', name: 'Michigan' },
        { abbr: 'MN', name: 'Minnesota' },
        { abbr: 'MS', name: 'Mississippi' },
        { abbr: 'MO', name: 'Missouri' },
        { abbr: 'MT', name: 'Montana' },
        { abbr: 'NE', name: 'Nebraska' },
        { abbr: 'NV', name: 'Nevada' },
        { abbr: 'NH', name: 'New Hampshire' },
        { abbr: 'NJ', name: 'New Jersey' },
        { abbr: 'NM', name: 'New Mexico' },
        { abbr: 'NY', name: 'New York' },
        { abbr: 'NC', name: 'North Carolina' },
        { abbr: 'ND', name: 'North Dakota' },
        { abbr: 'OH', name: 'Ohio' },
        { abbr: 'OK', name: 'Oklahoma' },
        { abbr: 'OR', name: 'Oregon' },
        { abbr: 'PA', name: 'Pennsylvania' },
        { abbr: 'RI', name: 'Rhode Island' },
        { abbr: 'SC', name: 'South Carolina' },
        { abbr: 'SD', name: 'South Dakota' },
        { abbr: 'TN', name: 'Tennessee' },
        { abbr: 'TX', name: 'Texas' },
        { abbr: 'UT', name: 'Utah' },
        { abbr: 'VT', name: 'Vermont' },
        { abbr: 'VA', name: 'Virginia' },
        { abbr: 'WA', name: 'Washington' },
        { abbr: 'WV', name: 'West Virginia' },
        { abbr: 'WI', name: 'Wisconsin' },
        { abbr: 'WY', name: 'Wyoming' }
    ],
    state_names : [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'District of Columbia',
        'Florida',
        'Georgia',
        'Guam',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ],
    transactions : [
        '0-5',
        '5-10',
        '10-25',
        '25-50',
        '50-100',
        '100+'
    ],
    specialties : [
        'Purchase Loans',
        'Refinancing',
        'Home Equity',
        'Mortgage Planning'
    ],

    expertise : [
        'Buyers',
        'Sellers',
        'Lot/Land',
        'Short Sales',
        'Luxury Properties',
        'New Construction',
        'Condominiums',
        'Investment Properties',
        'First Time Home Buyers',
        'Commercial Properties',
        'Investors',
        'Mobile Homes on Land they own',
        'Mobile homes on Land they want to purchase',
    ],

    associations : [
        'NAR',
        'NAHREP'
    ],

    certifications : [
        'Five-Star',
        'CDPE®',
        'ABR®',
        'CRS®',
        'CRE',
        'CCIM®',
        'RAA',
        'GRI',
        'CRB',
        'e-PRO',
        'RLI', 
        'MRP'
    ],

    loans : [
        'VA LOANS',
        'FHA',
        'USDA',
        'Conventional',
        'Short Sales',
        'Reverse Mortgages',
    ],

    preferences : [
        'Buyers',
        'Investors',
        'Sellers',
        'Short Sales',
        'Commercial Properties',
        'Land'
    ],

    languages : [
        'Arabic',
        'Bosnian',
        'Cantonese',
        'French',
        'Farsi',
        'German',
        'Greek',
        'Hebrew',
        'Hindi',
        'Italian',
        'Japanese',
        'Korean',
        'Mandarin',
        'Polish',
        'Portuguese',
        'Romanian',
        'Russian',
        'Spanish',
        'Tagalog',
        'Ukrainian',
        'Vietnamese'
    ],

    icons : [
        {
            'Assigned': {'icon' : 'assigned','color' : 'primary'},
            'Prospect': {'icon' : 'prospect','color' : 'primary'},
            'Listed': {'icon' : 'listed','color' : 'primary'},
            'In Escrow': {'icon' : 'inescrow','color' : 'primary'},
            'Closed Escrow': {'icon' : 'escrowclosed','color' : 'primary'},
            'Closed': {'icon' : 'closed','color' : 'primary'},
            'No Longer Engaged': {'icon' : 'nolongerengaged','color' : 'primary'},
            'On Hold': {'icon' : 'onhold','color' : 'primary'},
            'Client': {'icon' : 'client','color' : 'primary'},
            'Nurture': {'icon' : 'nurture','color' : 'primary'},
        }
    ],

    militaryBranch : [
        'Army',
        'Air Force',
        'Coast Guard',
        'Marine Corps',
        'Navy'
    ],

    referralTypes : [
        {value: 'Home Buyers', text: 'Buyers'},
        {value: 'Investors', text: 'Investors'},
        {value: 'Home Sellers', text: 'Sellers'},
        {value: 'Short Sales', text: 'Short Sales'},
    ],

    buyerStatuses : [
        {stage: 'Assigned', status: "Accepted: Trying to Reach Client", show: "admin"},
        {stage: 'Assigned', status: "I am trying to reach this Client", show: "agent"},
        {stage: 'Assigned', status: "48 hours: I have not reached this Client", show: "admin"},
        {stage: 'Prospect', status: "I'm communicating with this Client", show: "agent"},
        {stage: 'Prospect', status: "I have an appointment to show this Buyer properties", show: "agent"},
        {stage: 'Prospect', status: "Landis Client", show: "agent"},
        {stage: 'Prospect', status: "Connected to a lender", show: "agent"},
        {stage: 'Client', status: "I'm showing this Buyer properties", show: "agent"},
        {stage: 'Client', status: "I have submitted an offer for this Buyer", show: "agent"},
        {stage: 'In Escrow', status: "I am in escrow with this Client", show: "agent"},
        {stage: 'Closed Escrow', status: "Sold! I have closed escrow with this Client", show: "agent"},
        {stage: 'No Longer Engaged', status: "Client has another agent", show: "agent"},
        {stage: 'On Hold', status: "I'm nurturing this client (long term)", show: "agent"},
        {stage: 'No Longer Engaged', status: "Client is Unresponsive", show: "agent"},
        {stage: 'No Longer Engaged', status: "I'm not able to attend to this Client", show: "agent"},
        {stage: 'No Longer Engaged', status: "Other", show: "agent"}
    ],


    buyerStatuses_new : [
        "Assigned -  I am trying to reach this Client",
        "Prospect -  I'm communicating with this Client",
        "Prospect -  I have an appointment to show this Buyer properties",
        "Prospect -  Connected to a lender",
        "Client -  I'm showing this Buyer properties",
        "Client -  I have submitted an offer for this Buyer",
        "Client - I've set this client up with an MLS search",
        "In Escrow - I am in escrow with this Client",
        "Closed Escrow -  Sold! I have closed escrow with this Client",
        "Nurture - I'm nurturing this client (long term)",
        "Nurture - I've set this client up with an MLS search",
        "No Longer Engaged - Client has another agent",
        "No Longer Engaged -  Client is Unresponsive",
        "No Longer Engaged -  I'm not able to attend to this Client",
        "No Longer Engaged -  Other",
    ],

    sellerStatuses_new : [
        "Assigned - I am trying to reach this Client",
        "Prospect - I'm communicating with this Client",
        "Prospect - I have a listing appointment scheduled with this Seller",
        "Listed - I have signed a listing agreement with this Seller",
        "Listed - I have listed this Seller's property",
        "In Escrow - I am in escrow with this Client",
        "Closed Escrow -  Sold! I have closed escrow with this Client",
        "Nurture - I'm nurturing this client (long term)",
        "Nurture - I've set this client up with an MLS search",
        "No Longer Engaged - Client has another agent",
        "No Longer Engaged - Client is Unresponsive",
        "No Longer Engaged - I'm not able to attend to this Client",
        "No Longer Engaged - Other",
    ],

    lenderStatuses : [
        "New Lead",
        "Called: Left Message",
        "Called: No Contact",
        "Application Taken",
        "Not Interested",
        "Interested Follow Up",
        "DNC",
        "Bad Number"
    ],

    priceRanges : [
        'Under $50K',
        '$50k - $100k',
        '$100K - $150K',
        '$150K - $200K',
        '$200K - $250K',
        '$250K - $300K',
        '$300K - $400K',
        '$400K - $500K',
        '$500K - $600K',
        '$600K - $800K',
        '$800K - $1M',
        'Over $1M'
    ],

    rentalRanges : [
        '$500 - $750',
        '$750 - $1000',
        '$1,000 - $1,250',
        '$1,250 - $1,500',
        '$1,500 - $1,750',
        '$1,750 - $2,000',
        '$2,000 - $2,500',
        '$2,500 - $3,000',
        'Over $3,000'
    ],

    bestWays : [
        'Right Now',
        'Morning',
        'Afternoon',
        'Evening'
    ],

    sources : [
        'Homegain',
        'RealtyTrac',
        'PrimeSellerLead',
        'Expired',
        'Other:'
    ],

    homeTypes : [
        'Single Family',
        'Condominium',
        'Town Home',
        'Mobile Home',
        '2-4 Units',
        'Vacation Home',
        'Rental',
        'Land/Lot'
    ],

    timeFrames : [
        '0 - 3 Months',
        '3 - 6 Months',
        '6 - 12 Months',
        'Over 1 Year'
    ],

    reTimeFrames : [
        'Today',
        'This Week',
        'This Month',
        'This Year',
        'Over a Year Ago'
    ],

    statuses : [
        'Pre-Qualified',
        'Pre-Approved',
        'Haven\'t Applied Yet',
        'I don\'t know'
    ],

    reasons : [
        'Relocating',
        'Just Browsing',
        'Looking for an Investment',
        'Other'
    ],

    api_key : '6f46b803be8ec56b3fe72e6e24d2f729f62dc83c',
    secret_key : '515492aa8f247e832b1736d51329cd785eae7f92',
    API_URL : 'https://api.myagentfinder.com', // 'http://127.0.0.1:8000',
};

export default default_data;

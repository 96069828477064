// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useParams, Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import { getTableBodyUtilityClass, Grid } from "@mui/material";
import { SvgIcon } from "@mui/material";
import { CSVLink } from 'react-csv';
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import AuthService from '../../../services/auth.service';

import MDAlert from "components/MDAlert";

function DataTables(props) {
	const { index, state } = useParams();
	const [tableData, setTabledata] = useState({
		columns: [
			{ Header: "Action", accessor: "action", width: "20%" },
			{ Header: "Contact Info", accessor: "phone", width: "25%" },
			{ Header: "Details", accessor: "priceRange" },
			{ Header: "Location", accessor: "propertyCity", width: "7%" },
			{ Header: "Status", accessor: "currentStatus", width: "5%" },
			{ Header: "Date Assigned", accessor: "assignedDate" },
			{ Header: "Updates", accessor: "updateWithinDays" },
		],
		rows: [],
	});

	const [opportunities, setOpportunities] = useState([]);
	const [helpText, setHelpText] = useState("");
	const [userData, setUser] = useState({});
	const [hasTeam, setHasTeam] = useState(false);
	const [Agents, setAgents] = useState([]);
	const [teamMembers, setTeamMembers] = useState([]);
	const [update, setUpdate] = useState(false);
	const [accept, setAccept] = useState(false);

	useEffect(() => {
		if (Object.keys(props.userdata).length != 0) {
			const user = props.userdata;
			let opps = user.agent.opportunity;
			let team_opp = {};
			if (Object.keys(user.team).length != 0) {
				team_opp = user.team.data;
				Object.values(team_opp).map((opp) => opp.map((opp_id) => opps = [...opps, opp_id]));
				getTeamMembers(user);
				setHasTeam(user.team.members.length > 0 ? true : false);
			}
			setOpportunities(opps, state);
			filterOpps(opps, state);
			setUser(user);
		}
	}, [props.userdata])

	useEffect(() => {
		if (opportunities.length > 0) {
			filterOpps(opportunities, state);
		}
	  }, [hasTeam]);

	const handleAccept = (value) => {
		const filteredArray = tableData.rows.filter(obj => obj.id != value);
		tableData.rows = filteredArray;
		setTabledata(tableData);
		let data = {};
		data.accept = true;
		data.currentStatus = "Accepted: Trying to Reach Client";
		data.id = value;
		data.stageName = "Assigned";
		AuthService.updateOpp(data);
		setAccept(true);
		setTimeout(() => {
			window.location.reload();
		  }, 3000);
	}
	const handleDecline = (value) => {
		const filteredArray = tableData.rows.filter(obj => obj.id != value);
		tableData.rows = filteredArray;
		setTabledata(tableData);
		let data = {};
		data.decline = true;
		data.currentStatus = "Agent Declined Opportunity";
		data.id = value;
		data.stageName = "Open";
		AuthService.updateOpp(data);
		window.location.reload();
	}

	function HomeIcon(props) {
		if (props.icon == 'accepted') {
			return (
				<SvgIcon {...props}>
					<svg width="16" height="36" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_1652_35964)">
							<path d="M15.3337 7.15857C15.3337 6.37232 14.7337 5.7419 14.0003 5.7419H9.78699L10.427 2.50482C10.4403 2.43399 10.447 2.35607 10.447 2.27815C10.447 1.98774 10.3337 1.71857 10.1537 1.52732L9.44699 0.783569L5.06033 5.4444C4.81366 5.70649 4.66699 6.06065 4.66699 6.45024V13.5336C4.66699 13.9093 4.80747 14.2696 5.05752 14.5353C5.30756 14.801 5.6467 14.9502 6.00033 14.9502H12.0003C12.5537 14.9502 13.027 14.5961 13.227 14.0861L15.2403 9.09232C15.3003 8.9294 15.3337 8.7594 15.3337 8.57524V7.15857ZM0.666992 14.9502H3.33366V6.45024H0.666992V14.9502Z" fill="#375525" />
						</g>
						<defs>
							<clipPath id="clip0_1652_35964">
								<rect width="16" height="17" fill="white" transform="translate(0 0.0751953)" />
							</clipPath>
						</defs>
					</svg></SvgIcon>
			);
		} else if (props.icon == 'declined') {
			return (
				<SvgIcon {...props}>
					<svg width="16" height="36" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_1652_35965)">
							<path d="M15.3337 8.57519C15.3337 9.36145 14.7337 9.99186 14.0003 9.99186H9.78699L10.427 13.2289C10.4403 13.2998 10.447 13.3777 10.447 13.4556C10.447 13.746 10.3337 14.0152 10.1537 14.2064L9.44699 14.9502L5.06033 10.2894C4.81366 10.0273 4.66699 9.67311 4.66699 9.28353V2.20019C4.66699 1.82447 4.80747 1.46414 5.05752 1.19846C5.30756 0.932784 5.6467 0.783528 6.00033 0.783528H12.0003C12.5537 0.783528 13.027 1.13769 13.227 1.64769L15.2403 6.64145C15.3003 6.80436 15.3337 6.97436 15.3337 7.15853V8.57519ZM0.666992 0.783528H3.33366V9.28353H0.666992V0.783528Z" fill="#6F331B" />
						</g>
						<defs>
							<clipPath id="clip0_1652_35965">
								<rect width="16" height="17" fill="white" transform="translate(0 0.0751953)" />
							</clipPath>
						</defs>
					</svg></SvgIcon>
			);
		}
	}

	function EditIcon() {
		return (
			<SvgIcon {...props} sx={{ width: "30px", height: "24px" }}>
				<svg width="30" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM10.95 17.51C10.66 17.8 10.11 18.08 9.71 18.14L7.25 18.49C7.16 18.5 7.07 18.51 6.98 18.51C6.57 18.51 6.19 18.37 5.92 18.1C5.59 17.77 5.45 17.29 5.53 16.76L5.88 14.3C5.94 13.89 6.21 13.35 6.51 13.06L10.97 8.6C11.05 8.81 11.13 9.02 11.24 9.26C11.34 9.47 11.45 9.69 11.57 9.89C11.67 10.06 11.78 10.22 11.87 10.34C11.98 10.51 12.11 10.67 12.19 10.76C12.24 10.83 12.28 10.88 12.3 10.9C12.55 11.2 12.84 11.48 13.09 11.69C13.16 11.76 13.2 11.8 13.22 11.81C13.37 11.93 13.52 12.05 13.65 12.14C13.81 12.26 13.97 12.37 14.14 12.46C14.34 12.58 14.56 12.69 14.78 12.8C15.01 12.9 15.22 12.99 15.43 13.06L10.95 17.51ZM17.37 11.09L16.45 12.02C16.39 12.08 16.31 12.11 16.23 12.11C16.2 12.11 16.16 12.11 16.14 12.1C14.11 11.52 12.49 9.9 11.91 7.87C11.88 7.76 11.91 7.64 11.99 7.57L12.92 6.64C14.44 5.12 15.89 5.15 17.38 6.64C18.14 7.4 18.51 8.13 18.51 8.89C18.5 9.61 18.13 10.33 17.37 11.09Z" fill="#434CA0" />
				</svg>
			</SvgIcon>
		);
	}

	function getTeamMembers(userInformation){
		const team = userInformation.team.members.map(({ id, name,sfid }) => ({ id, name,sfid }));
		team.push({"id": userInformation.agent.id, "name" : userInformation.agent.firstname +" "+userInformation.agent.lastname, "sfid" : userInformation.agent.sfid});
		setTeamMembers(team);
		const agentValues = userInformation.team.members.map(({ id, name }) => ({ id, name }));
		agentValues.push({"id": userInformation.agent.id, "name" : userInformation.agent.firstname +" "+userInformation.agent.lastname});
		agentValues.push({"id" : 0, "name" : "All Agents"});
		setAgents(agentValues);
	}

	//csv handler
	const [csvData,setCsvData] = useState([]);
	const exportcolumns = [
		"assignedDate","bedrooms","bathrooms","buyerSeller","currentStatus","firstName","lastName","phone","oppemail","existingMortgageStatus","propertyStreet","propertyCity","propertyState","propertyZip","typeOfHome","priceRange","timeframe","reasonForBuyingSelling","referralFee","salesPrice","channelwebsite","leadcomment"
	];

	const handleExportCsv = () => {
		const fields = tableData.rows.map((item) => {
			const fieldValues = {};
			exportcolumns.forEach((fieldName) => {
			  fieldValues[fieldName] = item[fieldName];
			});
			return fieldValues;
		  });
		  setCsvData(fields);
	};

	const filterOpps = (opps, state) => {
		let dataTableData = {
			columns: [
				{
					Header: "Action", accessor: "id", Cell: ({ value }) =>
						<Link to={"/opp/" + value}>
							<EditIcon />
						</Link>

				},
				{
					Header: "Contact Info", accessor: "contact", Cell: ({ value: [firstname, lastname, mobile_number,opp_id] }) => (
						<>
							<Link to={"/opp/" + opp_id}>
								<MDTypography variant="subtitle2" fontWeight="bold" color="lightneutrals700">{firstname} {lastname}</MDTypography>
							</Link>
							<MDTypography variant="subtitle2" color="lightneutrals700">{mobile_number}</MDTypography>
						</>
					), width: "25%"
				},
				{
					Header: "Details", accessor: "details", Cell: ({ value: [range, buyerseller,bothbuyandsell] }) => (
						<>
							<MDTypography variant="subtitle2" color="lightneutrals700" sx={{ lineHeight: "0.5" }}>
								{buyerseller == "Buyer" ?
									range ?
										<MDBox color="black" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #6471ED", width: "35.5%", background: "#F0F1FD" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{bothbuyandsell ? buyerseller + "+ Seller":buyerseller}</MDTypography>
										</MDBox> :
										<MDBox color="black" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #6471ED", background: "#F0F1FD" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{bothbuyandsell ? buyerseller + "+ Seller": buyerseller}</MDTypography>
										</MDBox>
									:
									range ?
										<MDBox color="black" bgColor="solidrose" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #FFB1DA", width: "35.5%", height: "75%" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{bothbuyandsell ? buyerseller + "+ Buyer":buyerseller}</MDTypography>
										</MDBox>
										:
										<MDBox color="black" bgColor="solidrose" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #FFB1DA", height: "75%" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{bothbuyandsell ? buyerseller + "+ Buyer":buyerseller}</MDTypography>
										</MDBox>
								}
							</MDTypography>
							<MDTypography variant="subtitle2" color="lightneutrals700">{range}</MDTypography>
						</>
					),
				},
				{
					Header: "Location", accessor: "location", Cell: ({ value: [street, city, state, zip] }) => (
						<>
							<MDTypography variant="subtitle2" color="lightneutrals700">{street}</MDTypography>
							<MDTypography variant="subtitle2" color="lightneutrals700">{city},{state}</MDTypography>
							<MDTypography variant="subtitle2" color="lightneutrals700">{zip}</MDTypography>
						</>
					), width: "7%"
				},
				{ Header: "Status", accessor: "currentStatus", width: "5%" },
				{ Header: "Date Assigned", accessor: "assignedDate" },
			],
			rows: [],
		};

		state != "closed" ? dataTableData.columns.push(
			{
				Header: "Updates", accessor: "updatetime", Cell: ({ value: [days,status] }) => (
					<>
						{days > 0 ?
							<MDBox color="black" bgColor="solidgreenLighter" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>update within {days} Days</MDTypography>
							</MDBox>
							: days == 0 ? 
							<MDBox color="black" bgColor="solidwarning" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="gradientPeachDark" sx={{ fontWeight: "500" }}>Due Today </MDTypography>
							</MDBox>
							: days == null && status == 'Final Cancel' ? 
							<MDBox color="black" bgColor="solidwarning" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="gradientPeachDark" sx={{ fontWeight: "500" }}>Final Cancel</MDTypography>
							</MDBox>
							: days == null && status == 'Accepted: Trying to Reach Client'? 
							<MDBox color="black" bgColor="solidwarning" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>update within 2 Days</MDTypography>
							</MDBox>
							: days == null ? 
							<MDBox color="black" bgColor="solidwarning" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="gradientPeachDark" sx={{ fontWeight: "500" }}>Final Cancel</MDTypography>
							</MDBox>
							:
							<MDBox color="black" bgColor="soliderror" coloredShadow="none" borderRadius="lg" opacity={1} pt={0.5} pb={0.5} pl={1.3} pr={1.3}>
								<MDTypography variant="caption" color="solidlight" sx={{ fontWeight: "500" }}>{Math.abs(days)} days Overdue! </MDTypography>
							</MDBox>
						}
					</>
				)
			})	:	<></>;

		hasTeam && state != "cancelled" ? dataTableData.columns.push(
			{ 
				Header: "Assign to", accessor: "tableAssignedAgent", Cell: ({ value: [ name , row_id] }) => 		
						<Autocomplete
						sx={{width : "250px"}}
						onChange={(event,value) => {
							AssignOpp(value,row_id)
						}}
						inputprops={{ 'aria-label': 'controlled' }}
						options={teamMembers}
						getOptionLabel = {getOptionLabel}
						defaultValue = {name}
						isOptionEqualToValue={isOptionEqualToValue}
						renderInput={(params) => (
							<FormField {...params} label="Assign To " InputLabelProps={{ shrink: true }} />
						)}
						/>
			})	:	<></>;
		setTabledata(dataTableData);
		if (state == "overdue") {
			setHelpText('');
			dataTableData.rows = opps.filter((opp) => opp.updateWithinDays < 0);
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		if (state == "prospects") {
			setHelpText("Here you can view all of your accepted referrals! It is during this stage that you are either trying to reach the client, communicating, or have an appointment lined up. Make sure to update your status to 'I am trying to reach this client', once you begin your attempts. If the status is not updated within 48hrs, our ISA team will reach out to the client on your behalf to ensure that this connection is made! Click on the client's name to see more information and to select your status from the dropdown menu. Or, select the 'Action' button to update your status now!")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'Assigned' || opp.stageName == 'Prospect');
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		if (state == "pending") {
			setHelpText('');
			dataTableData = {
				columns: [
					{
						Header: "Action", accessor: "id", Cell: ({ value }) => (
							<>
								<Grid container>
									<Grid item pr={2}>
										<MDButton pr={2} variant="gradient" color="gradientgreen" onClick={() => handleAccept(value)}>
											<MDTypography variant="caption" color="gradientGreenDark" fontWeight="bold">
												<HomeIcon icon="accepted" /> Accept
											</MDTypography>
										</MDButton>
									</Grid>
									<Grid item>
										<MDButton variant="gradient" color="gradientPeach" onClick={() => handleDecline(value)}>
											<MDTypography variant="caption" color="gradientPeachDark" fontWeight="bold">
												<HomeIcon icon="declined" />Decline
											</MDTypography>
										</MDButton>
									</Grid>
								</Grid>
							</>), width: "20%"
					},
					{
						Header: "Details", accessor: "details", Cell: ({ value: [range, buyerseller,both] }) => (
							<>
								{buyerseller == "Buyer" ?
										<MDBox color="black" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #6471ED", width: "70%", background: "#F0F1FD" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{both ? buyerseller + "+ Seller":buyerseller}</MDTypography>
										</MDBox> 
									:
										<MDBox color="black" bgColor="solidrose" borderRadius="lg" opacity={1} p={1} sx={{ color: "#000000", border: "2px solid #FFB1DA", width: "70%", height: "75%" }}>
											<MDTypography variant="caption" color="gradientGreenDark" sx={{ fontWeight: "500" }}>{both ? buyerseller + "+ Buyer":buyerseller}</MDTypography>
										</MDBox>
								}
								<MDTypography variant="subtitle2" color="lightneutrals700">{range}</MDTypography>
							</>
						), width: "20%"
					},
					{
						Header: "Location", accessor: "location", Cell: ({ value: [street, city, state, zip] }) => (
							<>
								<MDTypography variant="subtitle2" color="lightneutrals700">{city},{state}</MDTypography>
								<MDTypography variant="subtitle2" color="lightneutrals700">{zip}</MDTypography>
							</>
						), width: "20%"
					},
				],
				rows: [],
			};
			dataTableData.rows = opps.filter((opp) => opp.currentStatus == 'Not Accepted');
		}
		if (state == "clients") {
			setHelpText("Here you can view all of your current clients! It is during this stage that you have either submitted an offer, showing a buyer properties, signed a listing agreement, or listed a seller's property. Click on the client's name to see more information and to select your status from the dropdown menu. Or, select the 'Action' button to update your status now!")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'Listed' || opp.stageName == 'Client');
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		if (state == "undercontract") {
			setHelpText("Here you can view all of the clients that are under contract and/or in escrow! It is during this stage that MyAgentFinder will send over a Docu-Sign with a copy of the referral agreement. Click on the client's name to see more information and to select your status from the dropdown menu. Or, select the 'Action' button to update your status now!")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'In Escrow');
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		if (state == "nurture") {
			setHelpText("Here you can view all of the clients that need more long term nurturing! There are two options to choose from during this stage. The first is, 'I’m nurturing this client (long term)' and this status is great for the clients that you have a close relationship with. Select, 'I’ve set this client up with an MLS search' if the client simply wants to be sent listings.")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'Nurture');
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		if (state == "closed") {
			setHelpText("Here you can view all of your closed clients! Once closed, MyAgentFinder will request a copy of the closing disclosure. It is also during this stage, that we all hi-5 one another and do a little victory dance :).")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'Closed Won' || opp.stageName == 'Closed Escrow');
		}
		if (state == "cancelled") {
			setHelpText("Here you can view all of the clients that you are no longer working with. It is during this stage that the client has either become unresponsive, is working with another agent or you are not able to attend to this clients needs. For the clients that become unresponsive, our team will attempt to reach that client for about 3-4 weeks to verify where they are at, and try and reconnect them with you!")
			dataTableData.rows = opps.filter((opp) => opp.stageName == 'No Longer Engaged' || opp.stageName == 'Closed Lost');
		}
		if (state == "allactive") {
			setHelpText("Here you can view all of the active referrals that have been sent to you from MyAgentFinder! Please see the dropdown menu to select all team members or to select a specific agent. You can also use the search bar to find a lead based off of name or location!")
			dataTableData.rows = opps.filter((opp) => (opp.stageName == 'Assigned' || opp.stageName == 'Prospect' || opp.stageName == 'Listed' || opp.stageName == 'Client' || opp.stageName == 'In Escrow' || opp.stageName == 'Nurture') && opp.currentStatus != "Not Accepted");
			dataTableData.rows = sortOppsTime(dataTableData.rows);
		}
		
		const updatedRows = Object.values(dataTableData.rows).map((opp) => {
			return {
					...opp,
					tableAssignedAgent: [opp.assignedAgentName, opp.id],
					contact: [opp.firstName, opp.lastName, opp.phone, opp.id],
					details: [opp.priceRange, opp.buyerSeller, opp.bothbuyandsell],
					location: [opp.propertyStreet, opp.propertyCity, opp.propertyState, opp.propertyZip],
					updatetime: [
							opp.nextStatusUpdateDate
									? Math.ceil(
												(new Date(opp.nextStatusUpdateDate) - new Date()) / (1000 * 60 * 60 * 24)
										)
									: null,
							opp.currentStatus,
					],
			};
	});
	
	dataTableData.rows = updatedRows;
		setTabledata(dataTableData);
		handleExportCsv();
	}

	function sortOppsTime(opps) {
		const oppsWithUpdatedTime = opps.map(opp => ({
			...opp,
			updatedtime: opp.nextStatusUpdateDate ? (Math.ceil((new Date(opp.nextStatusUpdateDate) - new Date()) / (1000 * 60 * 60 * 24))) : null
		}));
	
		return oppsWithUpdatedTime.sort((a, b) => a.updatedtime - b.updatedtime);
	}

	useEffect(() => {
		setAccept(false);
		if (opportunities.length > 0) {
			filterOpps(opportunities, state);
		}
	}, [state]);

	const getSelectedOption = (option, value) => option.id === value.id;

	function filterData(value) {
		if(value.id != 0){
			let filtered_opps = opportunities.filter((opp) => opp.assignedagentuuid == value.id);
			filterOpps(filtered_opps,state);
		} else {
			filterOpps(opportunities,state);
		}
	} 

	function AssignOpp(newagent,opp_id) {;
		AuthService.assignOppUpdate(newagent.sfid, opp_id);
		setUpdate(true);
	}
	function getOptionLabel (option) {
		if (option && option.name) {
			return option.name;
		  }else {
			let info = teamMembers.filter((team) => team.name == option);
			return info[0]? info[0].name : option;
		  }
	}
	const isOptionEqualToValue = (option, value) => {
		return option.name === value || option.id === value.id;
	  };
	  

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={3} pb={3}>
				<MDBox pb={3}>
					{state == 'allactive' ?
					<MDTypography variant="h1" color="soliddark">{index.charAt(0).toUpperCase() + index.slice(1)} : All Active</MDTypography>:
					state == 'undercontract' ?
					<MDTypography variant="h1" color="soliddark">{index.charAt(0).toUpperCase() + index.slice(1)} : Under Contract</MDTypography> 
					:<MDTypography variant="h1" color="soliddark">{index.charAt(0).toUpperCase() + index.slice(1)} : {state.charAt(0).toUpperCase() + state.slice(1)}</MDTypography>
					}
					<MDTypography variant="subtitle2" pt={1} color="lightneutrals700">
						{helpText} {state == 'closed' ? <Icon>mood</Icon> : <></>}
					</MDTypography>
				</MDBox>
				{update && (<MDAlert color="success" dismissible> Your Update is received and will update shortly</MDAlert>)}
				{accept && (<MDAlert color="success" dismissible> YaY !!!! You have accepted the referral opportunity</MDAlert>)}
				<Card pt={3}>
					<MDBox pt={3} pl={3} pr={3} lineHeight={1}>
						<Grid container>
							<Grid item xs={6} sm={6} md={6}>
							<MDTypography variant="h4" color="soliddark">
								Referrals
							</MDTypography>
							</Grid>
							{hasTeam ?
							<Grid item xs={6} sm={6} md={6} align="right">
							<Autocomplete
										onChange={(event, value) => filterData(value)}
										inputprops={{ 'aria-label': 'controlled' }}
										options={Agents}
										getOptionLabel = {(agent) => agent.name}
										isOptionEqualToValue={getSelectedOption}
										renderInput={(params) => (
											<FormField {...params} label="Filter By" InputLabelProps={{ shrink: true }} />
										)}
									/>
							</Grid> : 
							<></>	
						}

						</Grid>
					</MDBox>
					<DataTable table={tableData} canSearch />
				</Card>
				<MDBox ml={1} pt={4} align="right">
					<MDButton variant="gradient" color="solidPrimary" sx={{fontSize : "0.875rem", textTransform : "lowercase"}} onClick={handleExportCsv}>
						<CSVLink style= {{color: "#FFFF"}} data={csvData} filename={'Referral-data.csv'}>
							Export CSV
						</CSVLink>
					</MDButton>
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default DataTables;

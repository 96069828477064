// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import List from "@mui/material/List";
import MDButton from "components/MDButton";


function BrandToolKit(props) {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container direction="column">
                <Grid item xs>
                    <MDBox >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <MDTypography align="left" variant="h2" pt={5} pb={4} color="soliddark"><b>👋 Welcome to your Home & Money Lead Dashboard!</b></MDTypography>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <MDBox bgColor="white" p={3} sx={{ borderRadius: "12px" }}>
                                    <MDBox p={3} bgColor="blackout" justifyContent="space-between" alignItems="center" sx={{ borderRadius: "12px" }}>
                                        <MDTypography variant="h4" color="soliddark">Overview </MDTypography><br />
                                        <MDTypography variant="body2" color="lightneutrals">This is your hub for all things related to your work with us as a Home & Money Partner. Here, you can access:</MDTypography>
                                        <List component="ol" sx={{ listStyleType: 'disc', pl: 5, '& .MuiListItem-root': { display: 'list-item', }, }}>
                                            <ul color="lightneutrals">
                                                <li><MDTypography variant="body2" color="lightneutrals">Lead data</MDTypography></li>
                                                <li><MDTypography variant="body2" color="lightneutrals">Influencer referrals status </MDTypography></li>
                                                <li><MDTypography variant="body2" color="lightneutrals">Information about the program </MDTypography></li>
                                                <li><MDTypography variant="body2" color="lightneutrals">Tips and best practices </MDTypography></li>
                                                <li><MDTypography variant="body2" color="lightneutrals">And more! </MDTypography></li>
                                            </ul>
                                        </List>
                                    </MDBox>
                                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                                    <MDTypography align="left" variant="h3" pt={1} color="soliddark"><b>Take the Tour</b></MDTypography>
                                    <MDTypography variant="body2" pb={1} color="lightneutrals">Watch the video below to get a sense for navigating the portal.</MDTypography>
                                    <MDBox align="center" sx={{ position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '56.25%', marginTop: '8px', marginBottom: '16px' }}>
                                        {props.userdata.agent.is_sales_split ?
                                            <iframe style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }} width="100%" height="100%" src="https://www.loom.com/embed/a547d40c62fb4421bc2399cb8477521c?sid=35eca75b-fa4f-4a4d-b632-85ac47c30ef6" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
                                            : <iframe style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }} width="100%" height="100%" src="https://www.loom.com/embed/76f85e2a9d914d289eaa0f817013970e?sid=1345d4f7-0e92-4892-bae8-2665701374f3" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
                                        }
                                    </MDBox>
                                    <a href="/dashboard" target="_blank" rel="noopener noreferrer">
                                        <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem", width: "100%" }} >
                                            Go to home page
                                        </MDButton>
                                    </a>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
            <MDBox pt={2} pb={2}>
                <Footer />
            </MDBox>

        </DashboardLayout>
    );
}

export default BrandToolKit;
